<template>
  <div>
    <el-carousel height="360px">
      <el-carousel-item v-for="(pic,index) in pics" :key="index">
        <el-image :src="pic.bigImage"></el-image>
      </el-carousel-item>
    </el-carousel>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>{{title}}</span>
      </div>
      <div style="color: red; font-size: 16px; line-height: 16px; padding: 10px 0;">
        ￥{{price}}
        <span v-for="(label,index) in labels" :key="index" style="color: yellow; background: red; padding: 2px; font-size: 12px; display: inline-block; height: 12px; line-height: 12px;">{{label}}</span>
      </div>
      <el-form label-width="85px">
        <el-form-item v-for="(prop,index) in props" :key="index" :label="prop.name + '：'" style="margin-bottom: 0;">
          {{prop.value}}
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { detail } from "../../api/cdf";

export default {
  name: "CdfDetail",
  data() {
    return {
      goodsId: "",
      pics: [],
      title: "",
      price: 0.00,
      labels: [],
      props: [],
    }
  },
  created() {
    let that = this;
    that.goodsId = that.$route.query.id;
    that.getDetail();
  },
  methods: {
    getDetail: function () {
      let that = this;
      detail(that.goodsId).then(res => {
        res.photoList.forEach(item => {
          that.pics.push(item);
        });
        that.title = res.productName;
        that.price = res.salesPrice;
        that.labels = Object.assign([], res.activityLabels)
        res.props.forEach(item => {
          //if(item.type === 1){
          if(item.value !== ""){
            that.props.push(item);
          }
        });
        that.props.sort(function (a,b){
          return a.index - b.index;
        });
      }).catch();
    },
  },
}
</script>

<style>
.el-carousel .el-carousel__button{
  width: 2px;
}
.el-card.is-never-shadow{
  border: none;
  overflow: auto;
}
.el-card.is-never-shadow .el-card__header{
  padding: 15px 0 5px 0;
  color: dimgray;
}
.el-card.is-never-shadow .el-card__body{
  padding: 0;
  padding-top: 5px;
}
.el-form-item .el-form-item__label{
  line-height: 20px;
  padding: 0;
  text-align: left;
  color: #9e9fa3;
}
.el-form-item .el-form-item__content{
  line-height: 20px;
}
</style>

<style scoped>

</style>