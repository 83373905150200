import request from '../utils/request'

export function index() {
    return request({
        url: '/findSubjectList?pageSize=10&pageNum=1',
        method: 'get',
        params: {},
        data: {},
    })
}
export function search(keyword, pageSize, goodsId) {
    return request({
        url: '/findGoodsList',
        method: 'get',
        params: {
            keyword: keyword,
            pageSize: pageSize,
            goodsId: goodsId,
        },
        data: {},
    })
}
export function banner(topicInfoId) {
    return request({
        url: '/findTopicInfoById',
        method: 'get',
        params: {
            topicInfoId: topicInfoId,
        },
        data: {},
    })
}
export function list(topicInfoId, pageSize, pageNum) {
    return request({
        url: '/findTopicInfoGoodsList',
        method: 'get',
        params: {
            topicInfoId: topicInfoId,
            pageSize: pageSize,
            pageNum: pageNum,
        },
        data: {},
    })
}
export function brandInfo(brandId) {
    return request({
        url: '/findBrandInfoById',
        method: 'get',
        params: {
            brandId: brandId,
        },
        data: {},
    })
}
export function brandGoods(brandId, pageSize, goodsId) {
    return request({
        url: '/findGoodsList',
        method: 'get',
        params: {
            brandId: brandId,
            pageSize: pageSize,
            goodsId: goodsId,
        },
        data: {},
    })
}
export function detail(goodsId) {
    return request({
        url: '/findGoodsByGoodsId',
        method: 'get',
        params: {
            goodsId: goodsId,
        },
        data: {},
    })
}