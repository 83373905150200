<template>
  <div>
    <div style="padding: 10px 0;">
      <el-button type="primary" @click="getList(true)" style="float: right;">搜索</el-button>
      <el-input v-model="keywords" style="width: 75%;"></el-input>
    </div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>{{keywords}}的搜索结果</span>
      </div>
      <div style="padding: 10px; overflow:auto;" v-infinite-scroll="next" infinite-scroll-disabled="disabled">
        <div style="width: 46%; float: left; padding: 5px 2%; position: relative;" v-for="product in goods" :key="product.goodsId" @click="$router.push({path:'/cdf/detail', query:{id:product.goodsId}})">
          <el-image :src="product.smallImage" lazy></el-image>
          <div style="font-size: 12px; height: 28px; line-height: 14px;">{{product.productName}}</div>
          <div style="font-size: 14px; line-height: 14px; color: brown">￥{{product.salesPrice}}</div>
          <div style="position: absolute; top: 0; left: 0;">
            <span v-for="(label,index) in product.labels" :key="index" style="font-size: 10px; color: yellow; background: red; padding: 2px; font-weight: bolder; margin: 0 5px; display: inline-block">{{label}}</span>
          </div>
          <div v-if="product.count === 0" style="position: absolute; top: 0; right: 0;">无货</div>
        </div>
        <div v-if="!loading && !noMore" style="text-align: center; font-size: 12px; padding-top: 10px; clear: both" @click="next">点击加载更多</div>
        <div v-if="loading">加载中...</div>
        <div v-if="noMore">没有更多了</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { search } from "../../api/cdf";

export default {
  name: "CdfList",
  data() {
    return {
      keywords: "",
      title: "",
      size: 10,
      last: "",
      loading: true,
      noMore: false,
      goods: [],
    }
  },
  created() {
    let that = this;
    that.keywords = that.$route.query.keyword;
    that.getList(true);
  },
  methods: {
    getList: function (reset){
      let that = this;
      that.loading = true;
      if(reset){
        that.last = "";
      }
      search(that.keywords, that.size, that.last).then(res => {
        res.list.forEach(item => {
          that.goods.push(item);
          that.last = item.goodsId;
        });
        if(res.last === 1){
          that.noMore = true;
        }
        that.loading = false;
      }).catch();
    },
    next: function () {
      let that = this;
      that.getList(false);
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
}
</script>

<style>
.el-card.is-never-shadow{
  border: none;
  overflow: auto;
}
.el-card.is-never-shadow .el-card__header{
  padding: 15px 0 5px 0;
  color: dimgray;
}
.el-card.is-never-shadow .el-card__body{
  padding: 0;
  padding-top: 5px;
}
</style>

<style scoped>

</style>