<template>
  <div>
    <div style="padding: 10px 0;">
      <el-button type="primary" @click="$router.push({path:'/cdf/search', query:{keyword:keywords}})" style="float: right;">搜索</el-button>
      <el-input v-model="keywords" style="width: 75%;"></el-input>
    </div>
    <el-carousel height="180px">
      <el-carousel-item v-for="carousel in carousels" :key="carousel.id">
        <el-image :src="carousel.imageUrl" @click="$router.push({path:'/cdf/list', query:{id:carousel.url}})"></el-image>
      </el-carousel-item>
    </el-carousel>
    <el-card shadow="never" v-for="card in cards" :key="card.id">
      <div slot="header" class="clearfix">
        <span>{{card.title}}</span>
      </div>
      <el-image :src="card.pic" class="image" @click="$router.push({path:card.path, query:{id:card.url}})"></el-image>
      <div style="padding: 10px;">
        <div style="width: 46%; float: left; padding: 5px 2%; position: relative;" v-for="product in card.goods" :key="product.goodsId" @click="$router.push({path:'/cdf/detail', query:{id:product.goodsId}})">
          <el-image :src="product.smallImage"></el-image>
          <div style="font-size: 12px; height: 28px; line-height: 14px;">{{product.productName}}</div>
          <div style="font-size: 14px; line-height: 14px; color: brown">￥{{product.salesPrice}}</div>
          <div style="position: absolute; top: 0; left: 0;">
            <span v-for="(label,index) in product.labels" :key="index" style="font-size: 10px; color: yellow; background: red; padding: 2px; font-weight: bolder; margin: 0 5px; display: inline-block">{{label}}</span>
          </div>
          <div v-if="product.count === 0" style="position: absolute; top: 0; right: 0;">无货</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { index } from "../../api/cdf";

export default {
  name: "CdfIndex",
  data() {
    return {
      keywords: "",
      carousels: [],
      cards: [],
    }
  },
  created() {
    let that = this;
    that.init();
  },
  methods: {
    init: function () {
      let that = this;
      let url = "";
      index().then(res => {
        // eslint-disable-next-line no-console
        console.log(res);
        that.carousels = [];
        that.cards = [];
        res.data.forEach(item => {
          // eslint-disable-next-line no-console
          console.log(item.subjectType);
          switch (item.subjectType){
            case 1:
              item.content.forEach(info => {
                if(info.urlType === 0){
                  that.carousels.push({
                    id: info.id,
                    url: info.url,
                    imageUrl: info.imageUrl,
                  });
                }
              });
              break;
            case 5:
              url = item.content[0].url.replace("/brand/detail?id=", "");
              that.cards.push({
                title: item.title,
                pic: item.content[0].imageUrl,
                id: item.content[0].id,
                url: url,
                path: url == item.content[0].url ? "/cdf/list" : "/cdf/brand",
                goods: Object.assign({}, item.goods),
              });
              break;
          }
        })
        // eslint-disable-next-line no-console
        console.log(that.carousels);
        // eslint-disable-next-line no-console
        console.log(that.cards);
      }).catch();
    },
  },
}
</script>

<style>
.el-carousel .el-carousel__button{
  width: 2px;
}
.el-card.is-never-shadow{
  border: none;
}
.el-card.is-never-shadow .el-card__header{
  padding: 15px 0 5px 0;
  color: dimgray;
}
.el-card.is-never-shadow .el-card__body{
  padding: 0;
  padding-top: 5px;
}
</style>

<style scoped>

</style>
