import VueRouter from 'vue-router'
import Cdf from './components/Cdf.vue'
import CdfIndex from './components/cdf/Index.vue'
import CdfList from './components/cdf/List.vue'
import CdfBrand from './components/cdf/Brand.vue'
import CdfSearch from './components/cdf/Search.vue'
import CdfDetail from './components/cdf/Detail.vue'

const routes = [
    { path: '/', redirect: '/cdf' },
    {
        path: '/cdf',
        component: Cdf,
        children: [
            { path: '/', component: CdfIndex },
            { path: 'list', component: CdfList },
            { path: 'brand', component: CdfBrand },
            { path: 'search', component: CdfSearch },
            { path: 'detail', component: CdfDetail },
        ]
    },
];

const router = new VueRouter({
    routes
});

export {router};