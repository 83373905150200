import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import {router} from './router'
import './plugins/element.js'
import VConsole from 'vconsole/dist/vconsole.min.js'

if(process.env.NODE_ENV !== "production"){
  new VConsole() // 初始化
}
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.filter('dataFormat', function (value, fmt) {
  if(value === '' || value === undefined){
    value = 0;
  }
  let getDate = new Date(parseInt(value) * 1000);
  let o = {
    'M+': getDate.getMonth() + 1,
    'd+': getDate.getDate(),
    'h+': getDate.getHours(),
    'm+': getDate.getMinutes(),
    's+': getDate.getSeconds(),
    'q+': Math.floor((getDate.getMonth() + 3) / 3),
    'S': getDate.getMilliseconds()
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt;
});
Vue.prototype.showError = function (msg, die) {
  let options = {
    message: msg,
    type: 'error',
    duration: 3 * 1000,
  };

  if (die !== undefined && die){
    if (typeof die === 'function'){
      Vue.prototype.$set(options, 'onClose', die);
      Vue.prototype.$message(options);
      return false;
    } else {
      Vue.prototype.$message(options);
      return Promise.reject({
        code: 405,
        msg: msg,
        data: {}
      })
    }
  } else {
    Vue.prototype.$message(options);
    return false;
  }
};
Vue.prototype.showSuccess = function (msg, fuc) {
  let options = {
    message: msg,
    type: 'success',
    duration: 1000,
  };

  if (fuc !== undefined && fuc && typeof fuc === 'function'){
    Vue.prototype.$set(options, 'onClose', fuc);
    Vue.prototype.$message(options);
    return true;
  } else {
    Vue.prototype.$message(options);
    return true;
  }
};


new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app');
