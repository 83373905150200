import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    withCredentials: true,
});

service.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 304) {
            if (response.data !== undefined && response.data.code !== undefined) {
                if (response.data.code > 0) {
                    Message({
                        message: response.data.message,
                        type: 'error',
                        duration: 5 * 1000
                    })
                    return Promise.reject(response.data)
                } else {
                    return response.data.data
                }
            } else {
                return Promise.reject({
                    code: response.status,
                    msg: response.statusText,
                    data: response
                })
            }
        } else {
            return Promise.reject({
                code: response.status,
                msg: response.statusText,
                data: {}
            })
        }
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error)
    },
);

export default service